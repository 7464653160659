@import "mixins/mixins";

html, body {
  margin: 0;
  background-color: get-color(white);
  min-height: 100%;
  padding: 0;
}

html {
  display: flex;
}

body {
  flex: 1;
}

header {
  @include get(color, foregroundColor, $colors);
  @include get(background-color, accentColor, $colors);
}

.page {
  display: flex;
  margin: 0 auto 0;
  flex-direction: column;
  background-color: get-color(backgroundColor);
  height: 100%;
  position: relative;
  border-bottom: #4F5665 1px solid;
}

.content {
  display: flex;
  height: 100%;
  margin-bottom: 0;
  flex-direction: column;
}

.content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
}

@media screen and (max-width: 1100px) {
  .content {
    height: 100%;
    margin-left: 0;
  }
  .side-nav {
    border-right: 0;
    border-bottom: 1px solid gray;
    position: static;
    width: 100%;
  }
  .settings-tabs.navbar-default {
    border-top: 0;
  }
}

.progress-bar-insights{
  @include get(background-color,accentColor,$colors);
  display: inline-block;
  height:10px;
}

.info-window {
  background: #FFFFFF;
  padding: 10px;
  border-radius: 5px 0 0 5px;
}

.location-search {
  margin-left: 10px;
  width: 170px;
}

.layer-limit {
  width: 150px !important;
  display: inline !important;
}

.yt-video {
  max-width: 100%;
  display: block;
  margin: auto;
}

.range-input {
  height: 34px;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
  flex-wrap: wrap;
}

.col {
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-3 {
  position: relative;
  flex: 0 0 25%;
  max-width: 25%;
}

.word-break {
  word-break: break-word;
}

.abs-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
}