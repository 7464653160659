//TOP
.pt0 {
  padding-top: 0px !important;
}

.pt5 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: $size10 !important;
}

.pt20 {
  padding-top: $size20 !important;
}

.pt30 {
  padding-top: $size30 !important;
}

.pt40 {
  padding-top: $size40 !important;
}

.pt60 {
  padding-top: $size60 !important;
}

//BOTTOM
.pb10 {
  padding-bottom: $size10 !important;
}

.pb20 {
  padding-bottom: $size20 !important;
}

.pb40 {
  padding-bottom: $size40 !important;
}

.pb60 {
  padding-bottom: $size60 !important;
}

.pb100 {
  padding-bottom: $size100 !important;
}

//LEFT
.pl10 {
  padding-left: $size10 !important;
}

.pl20 {
  padding-left: $size20 !important;
}

.pl40 {
  padding-left: $size40 !important;
}

.pl50 {
  padding-left: $size50 !important;
}

.pl60 {
  padding-left: $size60 !important;
}

.pl70 {
  padding-left: $size70 !important;
}

.pl80 {
  padding-left: $size80 !important;
}

.pl100 {
  padding-left: $size100 !important;
}


//RIGHT
.pr10 {
  padding-right: $size10 !important;
}

.pr20 {
  padding-right: $size20 !important;
}

.pr40 {
  padding-right: $size40 !important;
}

.pr60 {
  padding-right: $size60 !important;
}

.pr70 {
  padding-right: $size70 !important;
}

.pr80 {
  padding-right: $size80 !important;
}

.pr100 {
  padding-right: $size100 !important;
}

.no-left-padding{
  padding-left: $size0 !important;
}

.no-right-padding{
  padding-right: $size0 !important;
}

.no-bottom-padding{
  padding-bottom: $size0 !important;
}

.no-top-padding{
  padding-top: $size0 !important;
}

.no-side-padding{
  padding-right: $size0 !important;
  padding-left: $size0 !important;
}

.no-padding{
  padding: $size0 !important;
}
