.insights-section {
  .panel {
    border:0;
  }

  .panel-with-filters, .form-group, .filters {
    @include flex;
    justify-content: space-between;
  }

  .control-label {
    margin-top:5px;
  }

  .table {
    .logo {
      max-width:40px;
      max-height:40px;
    }

    .valign-middle {
      td, th {
        vertical-align: middle;
      }
    }

    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4) {
      @include get(color,selectedSubItemColor,$colors);
    }

    td {
      span {
        display:inline-block;
        max-width:250px;
      }
    }

    th{
      @include get(border-bottom-color,selectedTimeItem,$colors);
    }
  }

  .map-container {
    display:inline-block;
    width:100%;
  }

  .search-panel {
    min-height:400px;
  }

  .google-maps-view {
    .form-control {
      height:400px;
    }
  }

  .form-group {
    &.checkbox {
      justify-content: flex-start;

      > div {
        width:20px;
      }

      .form-control {
        position:relative;
        margin-left:5px;
        outline:none;
        height:20px;
      }

      label {
        padding-left:10px;
      }
    }
  }
}
