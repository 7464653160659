@import "../mixins/mixins";

.menu-icon{
  height: 20px;
  width: 20px;
  margin-bottom: 5px;
}

.icon-bar{
  @include get(background-color,white,$colors);
}

.navbar{
  font-size: 1.3em;
  margin-bottom: 0 !important;
}

.navbar-right li{
  padding-right: 10px;
}

.navbar-custom {
  background-color: get-color(accentColor);
  color: get-color(white);
}

.navbar-custom .navbar-nav > li > a {
  @include get(color,white,$colors);
  @include get(background-color,transparent,$colors);
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 80px;
}

.navbar-custom .navbar-nav > li > a:link {
  @include get(background-color,transparent,$colors);
  text-decoration: none;
}

.navbar-custom .navbar-nav > li > a:visited {
  @include get(background-color,transparent,$colors);
  text-decoration: none;
}

.navbar-custom .navbar-nav > li > a:hover {
  @include get(background-color,accentColor,$colors);
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0.5;
}

.navbar-custom .navbar-nav > li > a:active {
  @include get(background-color,accentColor,$colors);
  text-decoration: none;
  opacity: 0.5;
}

.selected{
  @include get(background-color,menuSelected,$colors);
}

.navbar-custom .navbar-nav > .active > a, .navbar-custom .navbar-nav > .active > a:hover, .navbar-custom .navbar-nav > .active > a:focus {
  @include get(color,white,$colors);
  @include get(background-color,transparent,$colors);
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 80px;
}

.navbar-custom .navbar-brand {
  @include get(color,white,$colors);
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 80px;
}

.navbar-custom .navbar-toggle{
  padding-top: 20px;
  padding-bottom: 20px;
}

.navbar-custom .navbar-brand:hover {
  @include get(color,backgroundColor ,$colors);
}

.hyper-drive-title-par-one{
  display: block;
  height: 15px;
}

.hyper-drive-title-par-two{
  @include get(color,white,$colors);
  display: block;;
  height: 12px;
  opacity: 0.6;
  font-size: 12px;
  padding-top: 3px;
}

.button-nav-bar{
  height: 66px;
}

.brand-navbar{
  height: 78px !important;
}

.side-nav {
  @include get(background-color,sideMenuColor,$colors);
  font-size: 1.3em;
  text-align: justify;
  padding: 10px;

  a:hover{
    @include get(color,accentColor,$colors);
  }

  .selected-side-item{
    a{
      @include get(border-bottom-color,selectedChoiceColoer,$colors);
      @include get(color,darkGray,$colors);
      border-bottom: 2px solid;
      font-weight: bold;
    }
  }

  li{
    min-height: 60px;
    line-height: 60px;
  }
}

.side-bar-container{
  height: 100%;
  border: 0px;
}

.side-nav a {
  @include get(color,black,$colors);

}

.side-nav .container-fluid {
  margin: 0;
  padding: 0;
}

.side-nav-bar-li-a-choice{
  padding: 0px !important;
  padding-bottom: 10px !important;
  margin-left: 10px;
  margin-top: 15px;
  margin-right: 10px;
}

.active .side-nav-bar-li-a-choice {
  @include get(color,accentColor,$colors);
  font-weight: bold;
}

.extra-nav {
  @include get(background-color,sideMenuSubMenuColor,$colors);
  font-size: 1.3em;
  text-align: justify;

  a:hover{
    @include get(color,accentColor,$colors);
  }

  .selected-side-item{
    a{
      @include get(color,accentColor,$colors);
      font-weight: bold;
    }
  }

  li{
    min-height: 60px;
    line-height: 60px;
  }
}

.side-bar-container{
  height: 100%;
  border: 0px;
}

.extra-nav a {
  @include get(color,black,$colors);

}

.extra-nav .container-fluid {
  margin: 0;
  padding: 0;
}

.settings-tabs.navbar-default {
  border-top: 1px solid #E7E7E7;
}

.settings-tabs.side-nav .navbar-nav > li {
  text-transform: capitalize;
  font-size: 0.88em;
}

.settings-tabs .navbar-nav > .active > a {
  @include get(background-color,transparent,$colors);
}