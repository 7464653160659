.connection-info {
  display: flex;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  margin-left: 190px;
  margin-top: 21px;
  background: #FF0000;
  -moz-box-shadow: 0 0 3px #000000;
  -webkit-box-shadow: 0 0 3px #000000;
  box-shadow: 0 0 3px #000000;

  span {
    flex: 1;
  }
}

.not-connected:hover {
  cursor: pointer;
  opacity: .9;
}

.live-map-container, .live-map {
  display: flex;
  flex: 1;
}

.connected {
  background-color: #00FF00 !important;
}

.gm-style-iw * {
  overflow: hidden;
  color: #505050;
}

@keyframes slideIn {
  from {
    left: 99vw;
  }
  to {
    left: 70vw;
  }
}

@keyframes slideOut {
  from {
    left: 70vw;
    display: block;
  }
  to {
    left: 99.9vw;
    display: block;
  }
}

.packets-info {
  position: absolute;
  background: #FFFFFF;
  top: 20vh;
  right: 0;
  bottom: 15vh;
  left: 70vw;
  border: #A8A8A8 solid 1px;
  border-radius: 10px 0 0 10px;
  overflow: auto;
}

.packets-info::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.packets-info::-webkit-scrollbar
{
  width: 10px;
  background-color: #F5F5F5;
}

.packets-info::-webkit-scrollbar-thumb
{
  background-color: get-color(accentColor);
}

.packets-content {
  margin: 20px;
}

.show-info {
  animation-name: slideIn;
  animation-duration: 0.5s;
}

.close-info {
  left: 99.9vw;
  animation-name: slideOut;
  animation-duration: 0.5s;
}

.none-info {
  display: none;
}

.packet-info {
  overflow: hidden;
  word-wrap: break-word;
  margin-bottom: 10px;
}
