$phoneRatio: calc((375 / 198) + 0.02915);

.offer-preview-container {
  display: inline-flex;
  width: 10px;
}

.image-preview-container {
  width: 233px;
  height: 500px;
  position: relative;

  > img.iphone-img {
    width: inherit;
    height: inherit;
  }

  .phone-display-container {
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;

    .phone-display-wrapper {
      padding: 64px 17px 61px 18px;
      height: 100%;
    }
  }
}

// Challenge ios preview
.phone-display-wrapper {

  .iphone-preview {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .iphone-preview-container {
      position: relative;
      margin-right: -15px;
      overflow-y: scroll;
      display: flex;
      height: 100%;
      flex-direction: column;

      .cp-image-top {
        position: relative;
        color: #FFFFFF;
        overflow: hidden;
        flex: 1;

        img {
          width: 100%;
          height: 100%;
          filter: brightness(50%);
        }

        .cp-linear-gradient {
          height: 100%;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: linear-gradient(transparent, transparent, black);
        }

        .img-text-container {
          width: 100%;
          position: absolute;
          left: 0;
          bottom: 0;
          padding: calc(20px / #{$phoneRatio}) calc(15px / #{$phoneRatio});

          .cp-small-title {
            text-transform: uppercase;
            font-size: calc(12px/#{$phoneRatio});
            opacity: .9;
            font-weight: 100;
            letter-spacing: -0.22px;
            font-family: SFUIText-Regular;
            margin-top: -10px;
          }

          .hallenge-text {
            font-weight: 500;
          }

          .challenge-text {
            flex: 1;
            word-break: break-word;
          }

          .large-text {
            font-size: calc(26px/#{$phoneRatio});
            font-family: SFUIText-Regular;
            letter-spacing: -0.48px;
            font-weight: bold;
          }

          .ff-SFUI-Bold {
            font-family: SFUIText-Bold;
          }

          .earn {
            word-break: break-all;
            margin-left: calc(10px/#{$phoneRatio});
            padding-top: 2px;

            > div {
              text-align: right;
            }

            .points-collect {
              display: flex;
              justify-content: flex-end;
              align-items: center;

              svg {
                margin-left: calc(5px/#{$phoneRatio});
                width: 13px;
                height: 13px;
              }
            }
          }

          .container {
            width: 100% !important;

            .row {
              display: flex;
              align-items: flex-end;
            }
          }
        }
      }

      .cp-information-container {
        font-size: 8px;
        padding: calc(30px/#{$phoneRatio}) calc(15px/#{$phoneRatio});
        flex: 3;
        display: flex;
        flex-direction: column;
        font-size: calc(15px/#{$phoneRatio});
        line-height: calc(18px/#{$phoneRatio});
        font-family: SFUIText-Regular;

        .phone-description-area {
          font-family: SFUIText-Regular;
        }

        .cp-information-title {
          font-size: calc(12px/#{$phoneRatio});
          letter-spacing: calc(3px/#{$phoneRatio});
          font-family: SFUIText-Bold;
          text-align: center;
        }

        .pre-wrap {
          white-space: pre-wrap;
        }

      }
    }

  }
}

// Challenge Phone Preview Style
.challenge-phone-grid {
  margin: 0 60px;
}

.position-sticky {
  margin-top: 0px;
}

@media only screen and (min-width: 1200px) {
  .ipc-sticky {
    position: sticky;
    top: 50px;
  }

  .position-sticky {
    margin-top: 20px;
  }

  .challenge-phone-grid {
    margin: 0;
  }
}