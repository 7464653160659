.y-axis line {
  stroke-width: 0;
}

.y-axis path {
  stroke-width: 0;
}

.x-axis line {
  stroke-width: 0;
}

.x-axis path {
  stroke-width: 0;
}

.challenge-section {
  @include get(background-color,backgroundColor,$colors);
  flex: 1 1 100%;
  min-width: 650px;
  padding: 1%;
  align-self: flex-start;
}

.chart rect {
  fill: steelblue;
}

.chart text {
  @include get(fill,white,$colors);
  font: 10px sans-serif;
  text-anchor: end;
}

.graphStyle{
  width: 100%;
  height: 100%;
}

.overlayFocus {
  fill: none;
  pointer-events: all;
}

.svgGraphStyle{
  fill: #E9EBEE;
  opacity: 0.7;
  stroke: none;
  position:relative;
}

.graphLine{
  @include get(stroke,accentColor,$colors);
  stroke-width: 2.5px;
}

.tool-tip {
  @include get(background-color,darkGray,$colors);
  @include get(color,white,$colors);
  display: none;
  position: absolute;
  height: auto;
  opacity: 0.9;
  padding: 5px;
  border-radius: 0.3em;
  text-align: center;

  .stacked-bar-tooltip{
    min-width: 200px;
    padding: 5% 5%;

    h4{
      @include get(color,white,$colors);
      text-align: left;
      font-weight: bold;
    }
    div{
      text-align: left;
    }
  }
}

.line {
  fill: none;
  stroke-width: 2px;
}

.line-color{
  stroke-width: 2px;

  line{
    stroke: #F2F2F2;
  }
  path{
    stroke: #F2F2F2;
  }
}

.pie-chart-container{
  width:72%;
  margin-left: 15%;
}

@media screen and (max-width: 900px) {
  .pie-chart-container{
    width:72%;
    margin-left: 15%;
    margin-top: 10%;
  }
}

@media screen and (max-width: 800px) {
  .pie-chart-container{
    width:72%;
    margin-left: 15%;
    margin-top: 20%;
  }
}

.pie-chart-sum-text {
  @include get(fill,accentColor,$colors);
  font-size: 32px;
  font-weight: bold;
}

.chart-legend-container {
  margin: auto;
  width: 70%;
  text-align: center;
}

//D3.js charts
bar-graph {
  .line {
    fill: none;
    stroke: steelblue;
    stroke-width: 2px;
  }

  .grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
  }

  .grid path {
    stroke-width: 0;
  }

  .bottom-grid line {
    stroke-width: 0;
  }

  .bottom-grid path {
    stroke-width: 0;
  }

  .left-grid line {
    stroke-width: 0;
  }

  .left-grid path {
    stroke-width: 0;
  }

  .right-grid line {
    stroke-width: 0;
  }

  .right-grid path {
    stroke-width: 0;
  }

  .analytics {
    .text {
      display: none;
      // stroke-width:0.01px;
      stroke: black;
    }
  }
}

stacked-bar-graph {
  .line {
    fill: none;
    stroke: steelblue;
    stroke-width: 2px;
  }

  .grid line {
    stroke: lightgrey;
    stroke-opacity: 0.7;
    shape-rendering: crispEdges;
  }

  .grid path {
    stroke-width: 0;
  }

  .bottom-grid line {
    stroke-width: 0;
  }

  .bottom-grid path {
    stroke-width: 0;
  }

  .left-grid line {
    stroke-width: 0;
  }

  .left-grid path {
    stroke-width: 0;
  }

  .analytics {
    .text {
      @include get(fill,white,$colors);
      // display: none;
      stroke-width:0.01px;
    }
  }
}

.line-color{
  // stroke: red;
  stroke-width: 2px;

  line{
    stroke: #F2F2F2;
  }
  path{
    stroke: #F2F2F2;
  }
}

.area-fill{
  @include get(fill,areaChartColor,$colors);
}

.chart-container {
  @extend .flex-container;
  margin: 1px;
  align-items: stretch;
  align-self: flex-start;
}

.chart-section {
  flex: 1 1 45%;
  min-width: 400px;

  &.wide {
    flex: 1 1 95%;
  }

  .valign-middle {
    td, th {
      vertical-align:middle;
    }
  }

  .offer-img {
    width:250px;
  }

  .text-align-center {
    td, th {
      text-align:center;
    }
  }

  .no-hover {
    &:hover {
      @include get(background-color,transparent,$colors);
    }
  }

  .float-right {
    float:right;
  }
}

.accuracy-line {
  fill: none;
  stroke: #4B4B4C;
  stroke-width: 2px;
}
