
.span-align-middle{
  vertical-align: top;
}

.dashboard-common-section{
  @include flex;
  margin: auto !important;
  padding: 15px;
  flex-direction: row;
}

.dashboard-select-section{
  @extend .dashboard-common-section;
  width: 240px;
}

.dashboard-timeline-section{
  margin: auto;
  margin-right: 15px;
  font-size: 15px;
}

.dropdown-control-events-hover{
  @include get(background-color,accentColor,$colors);
  @include get(color,white,$colors)
}

.dashboard-dropdown-section-driver{
  @extend .dashboard-common-section;
  position:relative;

  .dropdown-wrapper {
    @include get(background-color,white,$colors);
    @include get(border-color,accentColor,$colors);
    position:absolute;
    top:49px;
    right:0px;
    min-width:196px;
    z-index:1;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    border-width: 0 1px 1px 1px;
    border-style: solid;

    .dropdown-item {
      padding:5px 10px;

      p{
        margin: 0 0 3px;
      }
    }
  }
}

.dashboard-dropdown-section{
  @extend .dashboard-common-section;
  position:relative;

  .dropdown-wrapper {
    @include get(background-color,white,$colors);
    @include get(border-color,accentColor,$colors);
    position:absolute;
    top:49px;
    right:0px;
    min-width:196px;
    z-index:1;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    border-width: 0 1px 1px 1px;
    border-style: solid;

    .dropdown-item {
      padding:5px 10px;

      &:hover {
        @include get(background-color,accentColor,$colors);
        @include get(color,white,$colors)
      }

      p{
        margin: 0 0 3px;
      }
    }
  }
}

//File management
.file-picker-drop-zone {
  @include get(background-color,gray,$colors);
  padding: 20% 20%;
  opacity: .5;
  text-align: center;

  &.over {
    @include get(background-color,accentColor,$colors);
    @include get(color,white,$colors);
    opacity: 1;
  }
}

.picked-file {
  @include get(background-color,white,$colors);
  flex: 1 1 20%;
  padding: 1%;
  align-self: flex-start;
}

.remove-file-button{
  @include flex;
  @include get(background-color,backgroundColor,$colors);
  @include get(color,black,$colors);
  width:30px;
  height:30px;
  position:absolute;
  outline:none;
  border:none;
  z-index:10;
  font-size:2em;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}

.edit-file-button{
  @include flex;
  @include get(background-color,backgroundColor,$colors);
  @include get(color,black,$colors);
  width:30px;
  height:30px;
  position:absolute;
  right: 0;
  outline:none;
  border:none;
  z-index:10;
  font-size:2em;
  flex-direction:column;
  justify-content:center;
  align-items:center;
}

.edit-file-button .button-color-edit {
  @include get(fill,black,$colors);
}

ux-dialog-container{
  display: flex !important;
  flex-direction: column;
  justify-content: center;

  > div {
    max-height: 100%;
    display: flex;

    > div {
      height: 100% !important;

      > ux-dialog {
        display: flex;
        height: 100%;
        flex-direction: column;

        > ux-dialog-body {
          overflow-y: auto;
        }
      }
    }
  }
}

.picked-image{
  max-height: 300px;
  max-width: 300px;
  cursor: pointer;
}

.image-container {
  text-align: center;

  div {
    display: inline-block;
    position: relative;
    zoom: 1;
  }

  img {
    display: block;
    width: 100%;
  }

  span {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(79, 86 , 101, 0.5);
  }

  .icon-span{
    @include get(color,white,$colors);
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    font-size: 3em;
  }
}

//splash
.fa {
  @include get(color,accentColor ,$colors);
}

.loader {
  text-align: center;
  padding: 0;
  box-sizing: border-box;
  height: 100%;

  .fa-spinner {
    display: inline-block;
    text-align: center;
    font-size: 52px;
    margin-top: 0px;
  }
}

.splash {
  @include get(background-color,backgroundColor,$colors);
  text-align: center;
  padding: 20% 0 0 0;
  box-sizing: border-box;
  height: 100%;
}

.splash .message {
  font-size: 72px;
  line-height: 72px;
  text-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
  text-transform: uppercase;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.splash .fa-spinner {
  display: inline-block;
  text-align: center;
  font-size: 72px;
  margin-top: 50px;
}

.ovarlay {
  @include get(background-color, backgroundColor, $colors);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: auto;
  opacity: 0.7;
}

.list-action-button {
  font-size: 1.7em;

  a {
    @include get(color,accentColor,$colors);
  }
  a:hover {
    @include get(color,selectedSubItemColor,$colors);
  }

  .glyphicon {
    margin-right: 10px;
    margin-left: 5px;
  }
}

.panel{
  @include get(border-color,black,$colors);
  @include get(background-color,backgroundColor,$colors);
  @include box-shadow(black, $colors, 0, 0, 0,0,null,null,null,0);
  border: 0px solid !important;

  .panel-heading{
    margin: 0 !important;
    padding: 0 !important;
  }

  .panel-body{
    @include get(background-color,white,$colors);
    margin-left: -5px;
    border-radius: 0.8em !important;

    .table{
      tbody td{
        border: 0px !important;
        max-width: 350px !important;
        word-wrap: break-word;
      }

      tbody td.verticalAligned{
        border: 0px !important;
        max-width: 350px !important;
        word-wrap: break-word;
        vertical-align: middle;
      }

      tbody tr:hover{
        @include get(background-color,lightAccent,$colors);
      }

      th{
        @include get(border-bottom-color,selectedTimeItem,$colors);
      }

      &.users-settings {
        tbody td{
          max-width: 130px !important;
        }
      }
    }
  }
}

.full-screen-dialog{
  position: absolute;
  top:0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 2%;
  width: 100% !important;
  height: 100% !important;
  min-height: 500px !important;

  .dialog-container{
    position: relative;
    top:0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;

    ai-dialog-header {
      height: 10%;
      padding: 1%;
    }

    ai-dialog-body{
      height: 80%;
      overflow-y: scroll;
      width: 100% !important;
      max-width: 100% !important;

      ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }

    }

    ai-dialog-footer{
      width: 100%;
      bottom: 0;
      right: 2%;
      height: 10%;

      .pager-dialog-container {
        margin: auto;
        width: 30%;
        padding-left: 5%;
      }
    }
  }
}

.modal-full {
  display: block;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.8);
}

.modal-content {
  margin: auto;
  display: block;
  max-width: 80%;
  max-height: 90%;
}
