//TOP
.mt5 {
  margin-top: $size5 !important;
}

.mt10 {
  margin-top: $size10 !important;
}

.mt20 {
  margin-top: $size20 !important;
}

.mt40 {
  margin-top: $size40 !important;
}

.mt60 {
  margin-top: $size60 !important;
}

//BOTTOM
.mb10 {
  margin-bottom: $size10 !important;
}

.mb20 {
  margin-bottom: $size20 !important;
}

.mb40 {
  margin-bottom: $size40 !important;
}

.mb60 {
  margin-bottom: $size60 !important;
}

.mb100 {
  margin-bottom: $size100 !important;
}

//LEFT
.ml10 {
  margin-left: $size10 !important;
}

.ml20 {
  margin-left: $size20 !important;
}

.ml40 {
  margin-left: $size40 !important;
}

.ml50{
  margin-left: $size50 !important;
}

.ml60 {
  margin-left: $size60 !important;
}

.ml80 {
  margin-left: $size80 !important;
}

.ml100 {
  margin-left: $size100 !important;
}

//RIGHT
.mr10 {
  margin-right: $size10 !important;
}

.mr20 {
  margin-right: $size20 !important;
}

.mr40 {
  margin-right: $size40 !important;
}

.mr60 {
  margin-right: $size60 !important;
}

.mr80 {
  margin-right: $size80 !important;
}

.mr100 {
  margin-right: $size100 !important;
}

.no-margin{
  margin: $size0 !important;
}

.auto-margin-sides {
  margin-left: auto !important;
  margin-right: auto !important;
}
