@import "mixins/mixins";

html, body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

@font-face {
  font-family: 'SFUIText-Bold';
  src: url('../fonts/SFUIText-Bold.ttf');
}

@font-face {
  font-family: 'SFUIText-Regular';
  src: url('../fonts/SFUIText-Light.ttf');
}

.white-background{
  @include get(background-color,white,$colors);
}

.black-background{
  @include get(background-color,black,$colors);
  @include get(color,white,$colors);
}

.black-background:hover{
  @include get(color,black,$colors);
}

.text-accent-color{
  @include get(color,accentColor,$colors);
}

.text-gray-color{
  @include get(color,darkGray,$colors);
}

.button-color-edit{
  @include get(fill,accentColor,$colors);
}

.text-align-center{
  text-align: center !important;
}

.text-align-left{
  text-align: left !important;
}

.text-align-right{
  text-align: right !important;
}

.bold-text{
  font-weight: bold;
}

.font-weight-normal {
  font-weight:normal !important;
}

.auto-width {
  width:auto !important;
}

.clear {
  clear:both;
}

.level-container{
  @include get(background-color,accentColor,$colors);
  @include get(color,white,$colors);
  border-radius: 10px;

  .list-group-item {
    @include get(color,darkGray,$colors);
  }
}

.right-separator{
  @include get(border-right-color,separatorColor,$colors);
  border-right: 2px solid;
}

.bottom-separator{
  @include get(border-bottom-color,separatorColor,$colors);
  border-bottom: 2px solid;
}

.btn-primary {
  @include get(background-color,accentColor,$colors);
  @include get(color,white,$colors);
  @include get(border-color,black,$colors);
}

.flex-container {
  @include flex;
  @include flex-wrap;
}

.header-title{
  @include get(color,fontColor,$colors);
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  margin-left: -5px;
  margin-top: 20px;
}

.header-title-insights{
  @include get(color,fontColor,$colors);
  font-size: 1.3em;
  text-align: left;
  margin-left: -5px;
  margin-top: 20px;
}

.action-icon:hover{
  text-decoration:none !important;
  svg path{
    @include get(fill,darkGray,$colors);
  }
}

.row-clickable{
  td
  a {
    color: inherit !important;
    text-decoration: none !important;
  }

  td {
    padding: 0 !important;

    :first-child {
      padding: 8px;
      display: block;
    }
  }
}

.cursor-pointer{
  cursor:pointer
}

.headline-right-box h4, .headline-right-box p {
  display: inline;
  vertical-align: top;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 28px;
}

.header-image h3 {
  position: relative;
  min-width: 120px;
}

.form-group .glyphicon-info-sign {
  @include get(color,darkGray,$colors);
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  width: auto;
}

.form-group .glyphicon-info-sign.info-sign-white {
  @include get(color,white,$colors);
}

textarea:focus, input:focus, input[type]:focus, select:focus{
  @include get(border-color,accentColor,$colors);
  @include box-shadow(accentColor, $colors, 0, 1px, 1px,0.075, 0, 0, 8px,0.6);
  border-width: 1px;
  border-style: solid;
  outline: 0 none;
}

button:focus{
  outline: none !important;
}

//Check box
.checkbox-position {
  position: relative;
  margin-bottom: 15px;
  padding-left: 15px;
  cursor: pointer;
}

.checkbox-position input {
  position:	absolute;
  z-index: -1;
  opacity: 0;
}

.control-indicator {
  @include get(background-color,selectedChoiceColoer,$colors);
  position: absolute;
  top: 2px;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.checkbox-position input:disabled ~ .control-indicator {
  pointer-events: none;
  background: gray;
}

.control-indicator:after {
  display: none;
  position: absolute;
  content: '';
}

.checkbox-position input:checked ~ .control-indicator:after {
  display: block;
}

/* Checkbox arrow */
.control-checkbox .control-indicator:after {
  top: 1px;
  left: 4px;
  width: 7px;
  height:11px;
  transform: rotate(45deg);
  border: solid #FFFFFF;
  border-width: 0 3px 3px 0;
}

.checkbox-graph{
  margin-left: 6px;
  margin-bottom: 13px;
}

//Select
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  padding-right: 25px!important;
  background: white url("../img/selectArrow.png") no-repeat 98% 50% !important;
}

select:disabled {
  @include get(background-color, disabledColor, $colors)
}

.flatpickr-day.selected,
.flatpickr-day.endRange.inRange,
.flatpickr-day.startRange.inRange
.flatpickr-day.endRange.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  @include get(background-color,accentColor,$colors);
  border: 0;
}

.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  @include box-shadow(accentColor, $colors, -10px, 0, 0,1,null,null,null,0)
}

.data-picker-background{
  @include get(background-color,white,$colors);
}

.hide-item{
  visibility: hidden;
}

//Pager
.pager-container {
  ul{
    margin: 0px !important;
    padding: 0px !important;
  }

  a{
    @include get(color,accentColor,$colors);
  }
}

.pager-navigation-button {
  cursor: pointer;
  width: 100px !important;
  text-align: center;
}

.pager-section{
  width: 60%;
  margin-left: 35%;
}

.pager-selected a {
  @include get(background-color,accentColor,$colors);
  @include get(color,white,$colors);
}

.pager-select-section{
  min-width: 65px
}

.table .vertical-center {
  vertical-align: middle;
}

.input-loader {
  margin-top: -30px;
  margin-right: 5px;

  .fa {
    font-size: 25px;
  }
}

.dropdown{
  .dropdown-toggle{
    border: 0px;
    width: 100%;
  }

  .dropdown-menu{
    width: 100%;
    max-height: 150px;
    overflow: auto;
  }

  .dropdown-menu-title{
    @include get(background-color,backgroundColor,$colors);
    font-size: 1em;
  }
}

.error-message {
  color: #A94442;
}

.has-error {
  .list-group-item {
    border-color: #A94442;
  }
}

.float-left {
  float:left;
}

.float-right {
  float:right;
}

.clear-field-button {
  position:relative;
  top:7px;
}

.top-border {
  border-top: 1px solid #AAAAAA;
}

.inline {
  display: inline !important;
}

.current-user-roles {
  .item {
    margin-bottom: 2px;
    margin-left: 10px;
  }
}

.manage-user-roles {
  &> label {
    display: block;
  }

  .button-wrapper {
    margin-top: 20px;
  }

  .bulk-action-users {
    padding: 10px;
    border-radius: 3px;
    margin-top: 10px;
  }
}

.list-group {
  list-style: none;
}

.form-group {
  position: relative;

  .variables-popup {
    color:black;
    background-color: white;
    position:fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    z-index: 10;
    border: 3px solid;
    border-radius: 3px;

    h3 {
      margin:0;
      margin-right:30px;
      padding:10px;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    .variables-dropdown {
      max-height: 300px;
      overflow: auto;

      .list-group-item {
        cursor: pointer;
      }
    }

    .close {
      position:absolute;
      top: 15px;
      right:15px;
    }
  }

  .variables-dropdown-button {
    margin-top: 10px;
  }
}

.timeline-picker {
  position:relative;

  .form-control {
    width: 150px;
    display:inline-block;
  }

  button {
    display:inline-block;
  }

  .date-display {
    display:inline-block;
    padding: 0 10px;
  }
}

.position-sticky {
  position: sticky !important;
  top: 40px !important;
}

.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: red;
  margin-left: 20px;
  margin-right: 6px;
  margin-top: 12px;
}

.redText {
  color: red;
}

.orangeText {
  color: orange;
}

.greenText {
  color: green;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.text-top {
  vertical-align: text-top;
}

.chip {
  display: inline-block;
  border-radius: 20px;
  background-color: #E0E0E0;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 5px;

  & * {
    font-weight: normal;
    cursor: pointer;
    background-color: #E0E0E0 !important;
  }

  & input {
    border: none;
    border-bottom: 1px solid black;
    padding: 0;
    width: 100px;

    &:focus {
      box-shadow: none !important;
      border: none;
    }
  }
}

.chip-selected, .chip-selected *  {
  background-color: #C4C2C4 !important;
}

.chips {
  margin-right: 15px;

  & .glyphicon {
    margin-right: 5px;
  }
}

.select-chip {
  border: none;

  &:focus {
    box-shadow: none !important;
    border: none;
  }
}

.map-upper-info {
  display: flex;

  > div {
    h4 {
      display: inline;
      vertical-align: top;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 28px;
      font-weight: bold;
    }

    p {
      display: inline;
      vertical-align: top;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 28px;
    }
  }
  > div + div {
    margin-left: 10px;
  }
}

.width-auto {
  width: auto !important;
}

.map-upper-info {
  display: flex;

  > div {
    h4 {
      display: inline;
      vertical-align: top;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 28px;
      font-weight: bold;
    }

    p {
      display: inline;
      vertical-align: top;
      font-family: 'Open Sans', sans-serif;
      font-size: 14px;
      line-height: 28px;
    }
  }
  > div + div {
    margin-left: 10px;
  }
}

.map-upper-info-btn {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.black-box-limited-table {

  .td-hover-action {
    cursor: pointer;

    &+tr {
      display: none;
    }
  }

  .td-hover-action.row-selected {
    background: #DDD;

    &+tr {
      display: table-row;
    }
  }
}

.collapsable-main + .collapsable {
  display: none;
}

.arrow-down {
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  border-style: solid;
  border-color: black;
  border-width: 0px 1px 1px 0px;
  -webkit-transform: translateY(3px) rotate(45deg);
  transform: translateY(3px) rotate(45deg);
  transition: border-width 150ms ease-in-out;

  &.ml-auto {
    margin-left: auto;
  }
}
input.disable-input {
  pointer-events: none !important;
}

// col-auto
.expendable-dropdown {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;

  > .collapsable {

    > .collapsable-slot {
      height: 100%;
      overflow: auto;
    }
  }
}

.ddd {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.mw-400 {
  max-width: 400px;
}

.exp-dropdown {
  display: block;
  flex-direction: column;
  border: 1px solid #EAEAEA;

  .collapsable {
    > .collapsable-slot {
      height: 100%;
      overflow: auto;
    }
  }

  + .exp-dropdown {
    margin-top: -1px;
  }

  > div {
    max-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .collapsable-main {
    font-weight: 500;
    cursor: pointer;
    padding: 10px 15px;
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;

    &.exp-active {
      background:#EAEAEA;

      .arrow-down {
        border-width: 1px 0px 0px 1px;
        -webkit-transform: translateY(6px) rotate(45deg);
        transform: translateY(6px) rotate(45deg);
      }
    }

    +.collapsable {
      transition: all 0.2s ease-out;

      &.show-dropdown {
        display: block;
      }
    }

    &.show-dropdown {
      .arrow-down {
        -webkit-transform: translateY(6px) rotate(-135deg);
        transform: translateY(6px) rotate(-135deg);
      }
    }
  }
}

.row-no-border {
  > td {
    border-top: none !important;
  }
}

.seperator {
  height: 1px;
  background: #EAEAEA;
  width: 100%;
  margin: 20px 0;
}

.collapsable-row-arrow {
  width: 10px;
  height: 10px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  display: inline-block;
  -webkit-transform: translateY(3px) rotate(45deg);
  transform: translateY(3px) rotate(45deg);
}

.collapsed .collapsable-row-arrow {
  -webkit-transform: translateY(6px) rotate(-135deg);
  transform: translateY(6px) rotate(-135deg);
}

.display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.text-overflow {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.layers-legend-layer-name {
  display: inline-block;
  max-width: 76%;
  padding-top: 6px;
}

.layers-legend-layer-name .control-indicator {
  top: 8px;
}
