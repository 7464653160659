.map-event-graph-section{
  @extend .flex-container;
  width: 100%;
  height: 100%;
}

.map-graph-section{
  flex: 1 1 80%;
  width: 85%;
  height: 85%;
  min-width: 600px;
  min-height: 300px;
  order: 1
}

.graph-section{
  position: relative;
  width: 100%;
  height: 30%;
}

.active-graph {
  @include get(color,white,$colors);
  @include get(background-color,accentColor,$colors);
  border-radius: 5px;
}

.disabled-container {
  @include get(background-color,darkGray,$colors);
  height: 80%;
  width: 100%;
  position: absolute;
  z-index: 999;
  opacity: 0.2;
  border-radius: 10px;
  cursor: not-allowed;
}

.map-section {
  margin: auto !important;
  width: 96%;
}

@media screen and (max-width: 1150px) {
  .event-section {
    width: 600px !important;
    flex-wrap: nowrap !important;
  }
  .point-section{
    width: 30%;
  }
  .event-show-section{
    width: 50%;
  }
}

.event-section {
  @extend .flex-container;
  width: 13%;
  min-width: 165px;
  height: 100%;
  order: 2;
  align-items: center;
}

.point-section{
  float: left;
  order: 1;
}

.event-show-section{
  order: 2;
}

.trip-location-section{
  @include flex;
  order: 2;
}
.title-section{
  @include flex;
}

.map-position {
  height: 700px
}

//small notebook 1900*1080, 23inch
@media screen and (max-height: 1170px){
  .map-position {
    height: 700px
  }
}

//up to 1680*1050, 22inch
@media screen and (max-height: 1070px){
  .map-position{
    height: 660px
  }
}

//in the middle of screens 1680*1050 and 1440*900
@media screen and (max-height: 970px){
  .map-position{
    height: 630px
  }
}

//up to 1440*900, 19inch
@media screen and (max-height: 900px){
  .map-position{
    height: 590px
  }
}

//up to 1366*768, 15inch
@media screen and (max-height: 800px) {
  .map-position{
    height: 540px;
  }
}

//very small screen
@media screen and (max-height : 500px){
  .map-position{
    height: 410px;
  }
}

.button-position{
  display: inline;
  margin-left: auto !important;
}

.analytics-overview-section-blackBox {
  flex: 1 0;
  min-width: 30px;
  align-self: flex-start;
}

.blackbox-tabs {
  @include get(color,accentColor,$colors);
}

.graph-flex-container {
  @extend .no-padding;
  @extend .no-margin;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}

.graph-flex-item {
  @extend .pt20;
  @extend .pr10;
  @extend .pb20;
  @extend .pl10;
  min-width: 350px;
}
