.settings-schema {
  margin-bottom: 10px;

  .panel-body {
    margin-left: 0 !important;
  }

  .panel-heading {
    @include get(background-color,transparent,$colors);

    .header-title {
      margin-left: 0 !important;
    }
  }

  .schema-table {
    tbody {
      border-top: 0;

      &.collapse.in, &.parent {
        background-color: #F5F4FB !important;
        border: 2px solid #E7E7E7;
      }
    }

    &.table.table {
      background-color: inherit;
    }

    td.collapsible:last-child  {
      vertical-align: middle;
    }

    .form-group {
      margin-bottom: 0 !important;

      .control-label {
        padding-left: 0;
      }
    }
  }

  .form-group {
    margin-left: 0 !important;
    margin-right: 0 !important;

    .control-label {
      text-align: left;
    }
  }

  .collapsible-header {
    border-bottom: 1px #333333 solid;
    margin-left: 0 !important;
    margin-bottom: 10px;
    position: relative;

    .header-title {
      margin-left: 0 !important;
    }
  }
}

.configuration-list {
  overflow:hidden;

  .header-title{
    margin-top:10px;
  }

  .configuration-list-item {
    border-top:1px solid black;
  }
}

.remove-item{
  text-align: center;
  margin-bottom: 10px;
}

.select-user-type {
  &> div {
    &> div {
      background-color: white;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }

    &> div.active {
      @include get(background-color, accentColor, $colors);
      color: white;
      font-size: bold;
    }
  }
}

.load-more-users {
  @include get(background-color, accentColor, $colors);
  padding: 10px;
  color: white;
  font-size: bold;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.input-box-users {
  width: 300px;
}

.bulk-action-users {
  @include get(background-color, accentColor, $colors);
  @include get(color, lightAccent, $colors);
}