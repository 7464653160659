.analytics-common-section{
  min-width: 150px;
  align-self: flex-start;
}

.analytics-overview-section {
  @extend .analytics-common-section;
  @include get(background-color,white,$colors);
  flex: 1 0;
  padding: 1%;
}

.analytics-overview-section-row {
  @extend .analytics-common-section;
  flex: 0 0 100%;
}

.overview-chart{
  @extend .analytics-common-section;
  @include get(background-color,backgroundColor,$colors);
  flex: 1 0;

  .panel-body{
    min-height: 470px;
  }
}

@media screen and (max-width: 1000px){
  .overview-chart{
    .panel-body{
      min-height: 380px;
    }
  }
}

.analytics-section {
  @include get(background-color,backgroundColor,$colors);
  flex: 1 1 100%;
  min-width: 550px;
  padding: 1%;
}

.form-control-graph{
  float: right;
  width: 130px !important;
}

.select-driving-infraction{
  width: 140px !important;
}

.no-data-content{
  height: 100%;
  text-align: center;
}
