@function get-color($color, $map : $colors){
  @if (map-has-key($map, $color)){
    $value: map-get($map, $color);

    @if type-of($value) == color {
      @return $value;
    }
  }
  @error "Unknown color: #{$color}";
}

@mixin get($prop,$var,$map){
  @if map-has-key($map, $var) {
    #{$prop}: map-get($map, $var) !important;
  }
  @else {
    @error "Unfortunately, value #{$var} could be retrieved from #{#{$map}x}";
  }
}

@mixin getRgb($prop,$var,$map){
  @if map-has-key($map, $var) {
    #{$prop}: map-get($map, $var) !important;
  }
  @else {
    @error "Unfortunately, value #{$var} could be retrieved from #{#{$map}x}";
  }
}

@mixin box-shadow($var, $map, $v1, $h1, $blur1,$opacity1, $v2, $h2, $blur2,$opacity2){
  @if map-has-key($map, $var){
    @if ($v2  != null){
      box-shadow:  $v1 $h1 $blur1  rgba(map-get($map, $var),$opacity1) inset, $v2 $h2 $blur2 rgba(map-get($map, $var), $opacity2) !important;
      -moz-box-shadow:  $v1 $h1 $blur1  rgba(map-get($map, $var),$opacity1) inset, $v2 $h2 $blur2 rgba(map-get($map, $var), $opacity2) !important;
      -webkit-box-shadow:  $v1 $h1 $blur1  rgba(map-get($map, $var),$opacity1) inset, $v2 $h2 $blur2 rgba(map-get($map, $var), $opacity2) !important;
    }
    @else{
      box-shadow:  $v1 $h1 $blur1  rgba(map-get($map, $var),$opacity1) !important;
      -moz-box-shadow:  $v1 $h1 $blur1  rgba(map-get($map, $var),$opacity1) !important;
      -webkit-box-shadow:  $v1 $h1 $blur1  rgba(map-get($map, $var),$opacity1) !important;
    }
  }
  @else{
    @error "Unfortunately, value #{$var} could be retrieved from #{#{$map}x}";
  }
}

@mixin flex-wrap{
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex{
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}
